@import '../../styles/vars';

.monthNav {
    position: sticky;
    top: 41px;
}

.dayContainer {
    position: fixed;
    top: 111px;
    width: 100%;
    max-width: inherit;
    height: calc(100% - 180px);
    overflow-y: auto;
}
