@import '../../styles/vars';

.button {
    position: relative;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 3px;
    border: none;
    background-color: $red;
    box-sizing: border-box;
    color: $font-gold;
    cursor: pointer;
    font-weight: 600;
    margin: 0;
    outline: none;
    padding: 6px 12px;
    text-align: center;
    text-decoration: none;
    transition: all 0.3;
    white-space: nowrap;
    flex-wrap: nowrap;
    box-shadow: 2px 2px 2px $light-grey;
    font-family: inherit;

    &:focus,
    &:active {
        z-index: 20;
    }

    &:hover {
        background-color: $light-red;
    }

    &[disabled] {
        cursor: not-allowed;
        background-color: $grey;
    }

    & a {
        color: inherit;
        text-decoration: none;
    }
}

.outline {
    border: 1px solid $grey;
    background-color: transparent;
    color: $font-grey;

    &:hover {
        color: $font-gold;
    }
}

.badge {
    position: absolute;
    top: 4px;
    right: 0;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: red;
}

.nav,
.sideNav {
    background-color: transparent;
    box-sizing: border-box;
    color: $font-grey;
    box-shadow: none;

    &:hover {
        background-color: transparent;
    }

    &[disabled] {
        background-color: transparent;
        color: $dark-grey;
    }
}

.nav {
    flex-direction: column;

    .badge {
        top: 35px;
    }
}

.sideNav {
    display: flex;
    font-size: 1rem;
}

.large {
    padding: 12px 18px;
    font-size: 16px;
}
