@import '../../styles/vars';

.yearDetails {
    padding: 5px;

    p {
        margin: 5px 0;
    }
    p:first-of-type {
        margin-bottom: 15px;
        font-size: 20px;
    }
    p:last-of-type {
        margin-top: 25px;
        font-size: 15px;
    }

    .westernYear {
        color: $font-grey;
    }
}

.middle {
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.rowItem {
    min-width: 25%;
}
