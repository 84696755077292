.wrapper {
    text-align: justify;
    margin: 20px 0;
}

.refTib,
.refEng {
    color: grey;
}

.refEng {
    font-size: 80%;
    margin-bottom: 5px;
}

.refTib {
    text-align: right;
    margin-top: 5px;
}
