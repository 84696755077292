@import '../../../styles/vars';

.container {
    padding: 15px 0 0;
    background-color: $bg-gold;
    min-height: 100vh;
    box-sizing: border-box;
}

.header {
    display: flex;
    margin-bottom: 20px;
}

.logo {
    width: 50px;
    height: 50px;
    margin: 5px 10px;
}

.heading {
    margin-top: 13px;
    text-align: left;
}

.name {
    margin: 0;
}

.address {
    margin: 0;
    color: $dark-grey;
    font-size: 0.85rem;
}

.navIcon {
    font-size: 20px;
    margin-right: 7px;
}

.link {
    text-decoration: none;
}

.collapse {
    position: absolute;
    top: 10px;
    right: 0;
}

.card {
    margin-top: 15px;
    max-width: 80%;
    max-height: 30vh;
}
