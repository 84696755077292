.icon {
    background: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0) calc(50% - 1px),
        rgba(192, 192, 192, 1) calc(50%),
        rgba(0, 0, 0, 0) calc(50% + 1px)
    );
    height: 30px;
    margin: 5px 45px;
    text-align: center;

    .imgWrapper {
        background: #fff;
        padding: 5px 10px;
    }

    img {
        width: 20px;
    }
}