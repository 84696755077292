@import '../../styles/vars';

.container {
    width: 100%;
    border-bottom: 1px solid $gold;
    cursor: pointer;

    &:focus {
        outline: none;
    }
}

.day {
    display: grid;
    grid-template-columns: 130px 10px auto 10px 100px;
    grid-template-areas: 'tib . overview . western';
}

.western,
.tibetan {
    border-bottom: 4px solid $gold;
    color: $red;
    display: flex;
    min-width: 50px;
    max-width: 100px;
    align-self: flex-end;
    justify-content: space-between;
}

.western {
    grid-area: western;
    padding: 5px 20px 5px 10px;
    text-align: right;
}

.tibetan {
    grid-area: tib;
    padding: 5px 10px 5px 20px;
    text-align: left;
}

.sunday {
    border-bottom-width: 5px;
    background-color: $bg-gold-light;
}

.today {
    background-color: $bg-gold;
    font-weight: 600;
    border-color: $red;

    & .tibetan,
    & .western {
        border-color: $red;
    }
}

.details {
    grid-area: overview;
    padding: 0 10px;
    text-align: left;
}

.moreDetails {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding-top: 10px;
    font-weight: normal;
    height: 0;
    transition: height 0.3s ease-in-out;
    overflow: hidden;
}

.detailRow {
    display: grid;
    width: 100%;
    grid-template-columns: 30px 30px 15px auto 25px;
    grid-template-areas: '. icon . detail .';
    align-self: flex-start;
    text-align: left;

    .icon {
        grid-area: icon;

        img {
            width: 20px;
        }
    }

    .moreDetail {
        grid-area: detail;

        h2 {
            margin: 0;
            font-size: 18px;
        }
    }
}

.detailRow:not(:last-child) .moreDetail {
    padding-bottom: 20px;
    border-bottom: 1px solid lightgrey;
}

.overview {
    min-height: $small-row-height;
    display: flex;
    align-items: flex-end;
}

.goodCut {
    color: $green;
}

.badCut {
    color: grey;
}

.moon {
    color: $moon;
    padding-top: 7px;
}

.icon {
    width: 20px;
    margin: 0 0 5px 7px;
}

@media screen and (max-width: 375px) {
    .day {
        grid-template-columns: 105px 10px auto 10px 75px;
    }

    .tibetan {
        padding-left: 10px;
    }

    .western {
        padding-right: 10px;
    }

    .detailRow {
        grid-template-columns: 15px 30px 10px auto 20px;
    }

    .detailRow:not(:last-child) .moreDetail {
        padding-bottom: 10px;
    }
}
