@import '../../styles/vars';

.search {
    position: sticky;
    top: 0;
    height: 30px;
    width: 90%;
    font-size: large;
    padding: 0 10px;
    margin-bottom: 15px;
}

.monthLine {
    background-color: #f9f1d7;
    text-align: center;
    padding: 20px;
    box-sizing: border-box;
    border-bottom: 1px solid $gold;}

.dayLine {
    border-bottom: 1px solid $gold;
    width: 100%;
    display: grid;
    grid-template-areas: "date . details";
    grid-template-columns: 80px 10px auto;
}

.date {
    grid-area: date;
    padding: 5px 10px;
    text-align: left;
    justify-self: stretch;
    align-self: end;
    border-bottom: 4px solid $gold;
}

.details {
    grid-area: details;
    padding: 5px 10px;
    text-align: left;
    justify-self: start;
    align-self: end;
}

.detail {
    cursor: pointer;
}