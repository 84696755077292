@import '../../../styles/vars';

.yearNav {
    position: sticky;
    top: 0;

    display: grid;
    justify-items: start;
    align-items: center;
    grid-template-columns: 40px 10px auto 10px 100px 10px 40px;
    grid-template-rows: calc(#{$small-row-height} - 5px) 5px;
    grid-template-areas: 'prev-nav . trans . eng . next-nav' 'prev-nav . . . . . next-nav';

    background-color: $bg-gold;
    color: $red;
    border-bottom: 1px solid $gold;
    min-height: $small-row-height;
}

@media screen and (max-width: 375px) {
    .yearNav {
        grid-template-columns: 30px 10px auto 10px 50px 10px 30px;
    }
}

.year {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.nextButton,
.prevButton {
    border: 0;
    border-radius: 0;
    background-color: $bg-gold;
    color: $font-grey;

    justify-self: stretch;
    align-self: stretch;

    &:focus {
        outline: none;
    }
}

.nextButton {
    grid-area: next-nav;
}

.prevButton {
    grid-area: prev-nav;
}

.trans {
    grid-area: trans;
}

.eng {
    grid-area: eng;
    margin-left: auto;
}

.clickable {
    cursor: pointer;
}
