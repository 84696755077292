@import '../../styles/vars';

.content {
    position: relative;
    top: 0;
    left: 0;
    width: 65vw;
    min-width: 300px;
    margin: 15vh auto;
    overflow: auto;
    outline: none;
    text-align: center;
}

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: auto;

    background-color: rgba(0, 0, 0, 0.5);
    z-index: 100;
}
