@import '../../styles/vars';

.signs {
    display: flex;
    flex-wrap: wrap;
}

.sign {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 64px;
    width: 72px;
    box-sizing: border-box;
    border: 1px $dark-grey solid;
    border-radius: 4px;
    margin: 0 5px 5px 0;
    padding: 2px;
    box-shadow: 0 -1px 3px #ddd;
    transition: border-width 0.3s, box-shadow 0.3s;
}

.selected {
    font-weight: bold;
    border-width: 3px;
    box-shadow: -3px 3px 3px #ddd;
}

.results {
    margin-top: 10px;
}

.result {
    margin-left: 30px;
}

.type {
    display: inline-block;
    width: 150px;
}

.day {
    font-weight: bold;
}
