@import '../../styles/vars';

.wrapper {
    position: relative;
    z-index: 0;
    width: 100%;
    box-sizing: border-box;
    border: 1px solid $light-red;
}

.innerWrapper {
    margin: 0 5px;
    border-right: 3px solid $light-red;
    border-left: 3px solid $light-red;
    padding: 10px 27px;

    & p:first-of-type {
        margin-top: 0;
    }

    & p:last-of-type {
        margin-bottom: 0;
    }
}

.content {
    height: 100%;
    overflow: auto;
}

.svg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
}
