@import './styles/vars';

.App {
    text-align: center;
}

.AppFooter {
    background-color: $bg-gold;
    color: $red;
    height: $row-height;
    width: 100%;
    position: fixed;
    bottom: 0;
    vertical-align: middle;
    box-shadow: 0 -1px 3px #ddd;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}

.main {
    max-width: 550px;
    margin: auto;
    margin-bottom: $row-height;
    background-color: white;
    min-height: 100vh;

    @media screen and (min-width: 550px) {
        box-shadow: 0 0 5px #333;
    }
}
