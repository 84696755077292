$red: #911818;
$light-red: #b83333;
$green: #158336;
$blue: #2d41b6;
$gold: #ff9d00; //#e5991f;
$font-gold: #ffcc00;
$bg-gold: #f9f1d7;
$bg-gold-light: #f9f1d76e;
$light-grey: #ece8e8;
$grey: #e4dede; //#f3eedd;
$dark-grey: #aba4a4;
$font-grey: #464242;
$moon: #2e2e2e;
