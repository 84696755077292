.wrapper {
    display: inline-grid;
    grid-template-rows: auto auto;
    width: 100%;
}

.name {
    margin: 7px 0;
}

.small,
.links {
    color: grey;
    font-size: 80%;
}

.link {
    & > img {
        margin-right: 2px;
    }

    & ~ .link::before {
        content: ' | ';
        color: grey;
    }
}
