@import '../../../styles/vars';

.dayNav {
    max-width: inherit;
    width: 100%;
    z-index: 2;

    display: grid;
    justify-items: start;
    align-items: center;
    background-color: $bg-gold;
    color: $red;
    border-bottom: 1px solid $gold;

    grid-template-columns: 40px 10px 45px 10px auto 10px 35px 50px auto 55px 10px 40px;
    grid-template-rows: calc(#{$row-height} / 2 - 5px) calc(#{$row-height} / 2) 5px;
    grid-template-areas: 'prev-nav . west-count divider tib-count . moon tib-day . eng . next-nav' 'prev-nav . west-count divider tib-count . moon day . eng . next-nav' 'prev-nav . . . . . . . . . . next-nav';
    min-height: $row-height;

    .tib {
        margin-left: auto;
    }
}

.nextButton,
.prevButton {
    border: 0;
    border-radius: 0;
    background-color: $bg-gold;
    color: $font-grey;
    justify-self: stretch;
    align-self: stretch;

    &:focus {
        outline: none;
    }
}

.nextButton {
    grid-area: next-nav;
}

.prevButton {
    grid-area: prev-nav;
}

.tib {
    grid-area: tib;
}

.tibDay {
    grid-area: tib-day;
}

.day {
    grid-area: day;
}

.moon {
    grid-area: moon;
    color: $dark-grey;
}

.eng {
    grid-area: eng;
    margin-left: auto;
}

.westCount {
    grid-area: west-count;
    margin-left: auto;
}

.tibCount {
    grid-area: tib-count;
}

.divider {
    grid-area: divider;
    justify-self: center;
}

.large {
    font-size: 2.2rem;
}

.clickable {
    cursor: pointer;
}

@media screen and (max-width: 375px) {
    .dayNav {
        grid-template-columns: 30px 10px 30px 10px auto 10px 30px 50px auto 40px 10px 30px;
    }

    .large {
        font-size: 2rem;
    }

    .small {
        font-size: 0.9rem;
        text-align: left;
    }
}
