@import '../../../styles/vars';

.monthNav {
    max-width: inherit;
    width: 100%;
    z-index: 2;
}

.monthNav,
.monthInsert {
    display: grid;
    justify-items: start;
    align-items: center;
    background-color: $bg-gold;
    color: $red;
    border-bottom: 1px solid $gold;
}

.monthNav,
.monthInsert {
    grid-template-columns: 40px 10px 40px 10px 110px 10px 30px auto 75px 10px 40px;
    grid-template-rows: calc(#{$row-height} / 2 - 5px) calc(#{$row-height} / 2) 5px;
    grid-template-areas: 'prev-nav . west-count . tib . tib-count . eng . next-nav' 'prev-nav . west-count . trans . tib-count . eng . next-nav' 'prev-nav . . . . . . . . . next-nav';
    min-height: $row-height;

    .tib {
        margin-left: auto;
    }
}

.nextButton,
.prevButton {
    border: 0;
    border-radius: 0;
    background-color: $bg-gold;
    color: $font-grey;
    justify-self: stretch;
    align-self: stretch;

    &:focus {
        outline: none;
    }
}

.nextButton {
    grid-area: next-nav;
}

.prevButton {
    grid-area: prev-nav;
}

.tib {
    grid-area: tib;
}

.trans {
    grid-area: trans;
}

.eng {
    grid-area: eng;
    margin-left: auto;
}

.westCount {
    grid-area: west-count;
    align-self: end;
    margin-left: auto;
}

.tibCount {
    grid-area: tib-count;
    align-self: start;
    padding-top: 7px;
}

.large {
    font-size: 2.2rem;
}

.westernMonthContainer {
    height: 25px;
    overflow: hidden;
}

.westernMonth {
    display: block;
    height: 0;
    opacity: 0;
    transition: height 0.3s, opacity 0.5s linear;

    &Active {
        height: auto;
        visibility: visible;
        opacity: 1;
    }
}

.clickable {
    cursor: pointer;
}

@media screen and (max-width: 375px) {
    .monthNav,
    .monthInsert {
        grid-template-columns: 30px 10px 40px 10px 70px 10px 30px auto 65px 10px 30px;
    }

    .large {
        font-size: 2rem;
    }

    .small {
        font-size: 0.9rem;
        text-align: left;
    }
}
