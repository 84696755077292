
.container {
    width: 100%;
    height: 100%;
    overflow: hidden;
    position: relative;
}

.view {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
  
    /* let the browser know we plan to animate
       each view in and out */
    will-change: transform;
}

// .view {
//     transition: transform 0.3s cubic-bezier(0.465, 0.183, 0.153, 0.946);
// }

.rightView {
    transform: translateX(100%);
}

.leftView {
    transform: translateX(-100%);
}

// .slideRight .mainView {
//     transform: translateX(-100%);
//   }
  
// .slideRight .rightView {
//     transform: translateX(0);
// }

// .slideLeft .mainView {
//     transform: translateX(100%);
// }

// .slideLeft .leftView {
//     transform: translateX(0);
// }
