@import './styles/vars';

@font-face {
    font-family: TertonSogyal;
    src: url('./statics/fonts/TertonSogyalPro.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'EB Garamond';
    src: url('./statics/fonts/EBGaramond-Regular.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'EB Garamond';
    src: url('./statics/fonts/EBGaramond-Italic.woff2') format('woff2');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

body {
    margin: 0;
    font-family: 'EB Garamond', serif;
    font-size: 1.1rem;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: $red;

    @media screen and (min-width: 560px) {
        background: no-repeat url('./statics/images/WoodSnakeBG.jpg');
        background-size: cover;
        background-attachment: fixed;
        background-position: center;
    }
}

a {
    color: $font-grey;
    text-decoration: none;
}

.tib-text,
.tib-long-text,
.tib-text-small {
    font-family: TertonSogyal;
    line-height: 14px;
    font-size: 15px;
}

.tib-long-text {
    line-height: 30px;
}

.tib-text-small {
    font-size: 11px;
    line-height: 20px;
}

@media screen and (max-width: 375px) {
    body {
        font-size: 1rem;
    }
}
