@import '../../styles/vars';

.dayNav {
    position: sticky;
    top: 0;
}

.dayDetails {
    padding: 10px 25px 20px;
    border-right: 1px solid $gold;
    border-left: 1px solid $gold;
    height: calc(100% - 72px);
    box-sizing: border-box;
    overflow-y: auto;
}

.dayInfo {
    text-align: left;
    font-size: 0.9rem;
    margin-bottom: 20px;
}

.detailSection {
    text-align: justify;

    & + .detailSection {
        margin-top: 20px;
    }

    h2 {
        font-size: 18px;
        text-align: center;
    }
}

.icon {
    background: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0) calc(50% - 1px),
        rgba(192, 192, 192, 1) calc(50%),
        rgba(0, 0, 0, 0) calc(50% + 1px)
    );
    height: 30px;
    margin: 5px 45px;
    text-align: center;

    .imgWrapper {
        background: #fff;
        padding: 5px 10px;
    }

    img {
        width: 20px;
    }
}

.goodCut {
    color: $green;
}

.badCut {
    color: grey;
}
