.wrapper {
    position: relative;
    z-index: 0;
    width: 100%;
    height: 100%;
    padding: 25px 27px;
    box-sizing: border-box;
}

.content {
    height: 100%;
    overflow: auto;
}

.svg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
}
